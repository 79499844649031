import { template as template_d60ff6d8d34a4951a7504d41d9d9d6f0 } from "@ember/template-compiler";
const WelcomeHeader = template_d60ff6d8d34a4951a7504d41d9d9d6f0(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
