import { template as template_117e854551534f5aaf5abbc3c5457e36 } from "@ember/template-compiler";
const FKLabel = template_117e854551534f5aaf5abbc3c5457e36(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
