import { template as template_39b3ee0db1424139b480b033ae86eb37 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_39b3ee0db1424139b480b033ae86eb37(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
